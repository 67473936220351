














































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IImportHistory } from '@/store/types/IImportHistory';

@Component({
  components: {},
})
export default class DashboardImportsTabDesktopItem extends Vue {
  @Prop() imp!: IImportHistory;
  //code smell
  number(value: any) {
    return this.$options!.filters!.number(value);
  }
  async deleteUpload(FileUploadId: number) {
    await ExplorerDataModule.deleteImport({
      id: FileUploadId,
      modal: this.$bvModal,
    });
  }
}
