
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBook } from '@/store/types/IBook';
import dashboardbookstabmobileitem from '@/pages/dashboard/components/DashboardBooks/DashboardBooksTabMobileItem.vue';

@Component({
  components: {
    dashboardbookstabmobileitem,
  },
})
export default class DashboardBooksTabMobile extends Vue {
  @Prop() books!: IBook[];
}
