





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICalendarValue } from '@/store/types/ICalendarValue';
import { IExportHistory } from '@/store/types/IExportHistory';
import dashboardexportstabdesktopitem from '@/pages/dashboard/components/DashboardExports/DashboardExportsTabDesktopItem.vue';

@Component({
  components: {
    dashboardexportstabdesktopitem,
  },
})
export default class DashboardExportsTabDesktop extends Vue {
  @Prop() exports!: IExportHistory[];
  calendarEndDate = new Date();

  destinationTitle(exp: IExportHistory) {
    return exp.ExportDestinationId === 1
      ? exp.ExportDestination
      : exp.ExportIntegration;
  }

  get calendarValues() {
    // the map removes the time element for the date
    // the reduce then removes duplicate dates and generates a count for each date
    // see // https://dev.to/marinamosti/removing-duplicates-in-an-array-of-objects-in-js-with-sets-3fep
    const retval = this.exports
      .map((i) => ({
        date: new Date(i.ExportCreatedUtc).toDateString(),
        count: 1,
      }))
      .reduce((acc: ICalendarValue[], current) => {
        const x = acc.find(
          (item: ICalendarValue) => item.date === current.date,
        );
        if (!x) {
          return acc.concat(current);
        } else {
          x.count++;
          return acc;
        }
      }, []);
    return retval;
  }
}
