var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.exporthistory.DashboardStatusId === 6)?_c('div',[(_vm.showlabel)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.$t('TL_EXPORTED_RECORD_COUNT')))])]):_vm._e(),_vm._v(" "),(_vm.exporthistory.ExportDestinationId === 1)?_c('span',[_vm._v("\n    "+_vm._s(_vm.$t('TL_EXPORTED_RECORD_COUNT_RECORDS_FROM', {
        records: _vm.number(
          _vm.exporthistory.DownloadResultTotalRecordsDownloadedCount
        ),
        books: _vm.number(
          _vm.exporthistory.DownloadResultDownloadedBooksCount
        ),
      }))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.exporthistory.ExportDestinationId === 2)?_c('span',[_vm._v("\n    "+_vm._s(_vm.$t('TL_EXPORTED_RECORD_COUNT_RECORDS_FROM', {
        records: _vm.number(
          _vm.exporthistory.IntegrationResultsTotalPublishedRecordCount
        ),
        books: _vm.number(
          _vm.exporthistory.IntegrationResultsPublishedBooksCount
        ),
      }))+"\n  ")]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }