



























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBook } from '@/store/types/IBook';
import FeatureFlagsModule from '@/store/modules/FeatureFlagsModule';

@Component({
  components: {},
})
export default class DashboardBooksTabDesktopItem extends Vue {
  @Prop() book!: IBook;

  // get featureFlagShowBookImages(): boolean {
  //   return FeatureFlagsModule.featureFlagShowBookImages;
  // }
}
