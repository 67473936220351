












































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBook } from '@/store/types/IBook';

@Component({
  components: {},
})
export default class DashboardBooksTabMobileItem extends Vue {
  @Prop() book!: IBook;
}
