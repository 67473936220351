


























import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import LayoutModule from '@/store/modules/LayoutModule';

import dashboardimportstabmobile from '@/pages/dashboard/components/DashboardImports/DashboardImportsTabMobile.vue';
import dashboardimportstabdesktop from '@/pages/dashboard/components/DashboardImports/DashboardImportsTabDesktop.vue';

@Component({
  components: {
    dashboardimportstabmobile,
    dashboardimportstabdesktop,
  },
})
export default class DashboardImportsTab extends Vue {
  get imports() {
    return ExplorerDataModule.Imports;
  }
  get isMobile() {
    return LayoutModule.isMobile;
  }
}
