

































import { IExportHistory } from '@/store/types/IExportHistory';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class ExportedRecordCount extends Vue {
  @Prop() exporthistory!: IExportHistory;
  @Prop() showlabel!: boolean;
  //code smell
  number(value: any) {
    return this.$options!.filters!.number(value);
  }
}
