




































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import LayoutModule from '@/store/modules/LayoutModule';
import { IBook } from '@/store/types/IBook';
import dashboardbookstabmobile from '@/pages/dashboard/components/DashboardBooks/DashboardBooksTabMobile.vue';
import dashboardbookstabdesktop from '@/pages/dashboard/components/DashboardBooks/DashboardBooksTabDesktop.vue';

@Component({
  components: {
    dashboardbookstabmobile,
    dashboardbookstabdesktop,
  },
})
export default class DashboardBooksTab extends Vue {
  searchText = '';
  sortBy = 'lasthighlighted';
  //todo not translated
  sortByOptions = [
    { value: 'lasthighlighted', text: 'sort by last highlighted' },
    { value: 'booktitle', text: 'sort by title' },
    { value: 'bookauthor', text: 'sort by author' },
    { value: 'highlightcount', text: 'sort by highlight count' },
  ];

  get isMobile() {
    return LayoutModule.isMobile;
  }

  get imports() {
    return ExplorerDataModule.Imports;
  }

  get filteredbooks() {
    //return ExplorerDataModule.Books;
    let sortedBooks: IBook[] = [];
    switch (this.sortBy) {
      case 'lasthighlighted':
        sortedBooks = ExplorerDataModule.Books.map((b) => b).sort(
          (a, b) =>
            +new Date(b.LastHighlighted) -
            +new Date(a.LastHighlighted),
        );
        break;
      case 'booktitle':
        sortedBooks = ExplorerDataModule.Books.map(
          (b) => b,
        ).sort((a, b) => a.BookTitle.localeCompare(b.BookTitle));
        break;
      case 'bookauthor':
        sortedBooks = ExplorerDataModule.Books.map(
          (b) => b,
        ).sort((a, b) => a.BookAuthor.localeCompare(b.BookAuthor));
        break;
      case 'highlightcount':
        sortedBooks = ExplorerDataModule.Books.map((b) => b).sort(
          (a, b) => b.RecordCount - a.RecordCount,
        );
        break;
    }
    if (this.searchText.length === 0) {
      return sortedBooks;
    } else {
      return sortedBooks.filter(this.bookFilter(this.searchText));
    }
  }

  bookFilter(searchText: string) {
    return (book: IBook) => {
      return (
        book.BookTitle.toLowerCase().includes(
          searchText.toLowerCase(),
        ) ||
        book.BookAuthor.toLowerCase().includes(
          searchText.toLowerCase(),
        )
      );
    };
  }
}
