
























import { IExportHistory } from '@/store/types/IExportHistory';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class ExportInitiationBadge extends Vue {
  @Prop() exporthistory!: IExportHistory;
}
