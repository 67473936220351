






































import { Component, Vue } from 'vue-property-decorator';

import languageselector from '@/pages/settings/components/languageselector.vue';

@Component({
  components: {
    languageselector,
  },
})
export default class DashboardWelcome extends Vue {}
