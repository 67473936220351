


































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import LayoutModule from '@/store/modules/LayoutModule';
import dashboardexportstabdesktop from '@/pages/dashboard/components/DashboardExports/DashboardExportsTabDesktop.vue';
import dashboardexportstabmobile from '@/pages/dashboard/components/DashboardExports/DashboardExportsTabMobile.vue';

@Component({
  components: {
    dashboardexportstabdesktop,
    dashboardexportstabmobile,
  },
})
export default class DashboardExportsTab extends Vue {
  get exports() {
    return ExplorerDataModule.Exports;
  }

  get isMobile() {
    return LayoutModule.isMobile;
  }

  get imports() {
    return ExplorerDataModule.Imports;
  }
}
