













































































import { Component, Vue } from 'vue-property-decorator';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import LayoutModule from '@/store/modules/LayoutModule';
import Logoloadingindicator from '@/components/logoloadingindicator.vue';
import dashboardexportstab from '@/pages/dashboard/components/DashboardExports/DashboardExportsTab.vue';
import dashboardimportstab from '@/pages/dashboard/components/DashboardImports/DashboardImportsTab.vue';
import dashboardbookstab from '@/pages/dashboard/components/DashboardBooks/DashboardBooksTab.vue';
import dashboardwelcome from '@/pages/dashboard/components/DashboardWelcome.vue';

@Component({
  components: {
    Logoloadingindicator,
    dashboardwelcome,
    dashboardexportstab,
    dashboardimportstab,
    dashboardbookstab,
  },
})
export default class DashboardPage extends Vue {
  get imports() {
    return ExplorerDataModule.Imports;
  }
  get books() {
    return ExplorerDataModule.Books;
  }

  get exports() {
    return ExplorerDataModule.Exports;
  }

  get isMobile() {
    return LayoutModule.isMobile;
  }
}
