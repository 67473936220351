











import { Component, Prop, Vue } from 'vue-property-decorator';
import { IExportHistory } from '@/store/types/IExportHistory';
import dashboardexportstabmobileitem from '@/pages/dashboard/components/DashboardExports/DashboardExportsTabMobileItem.vue';

@Component({
  components: {
    dashboardexportstabmobileitem,
  },
})
export default class DashboardExportsTabMobile extends Vue {
  @Prop() exports!: IExportHistory[];

  destinationTitle(exp: IExportHistory) {
    return exp.ExportDestinationId === 1
      ? exp.ExportDestination
      : exp.ExportIntegration;
  }
}
