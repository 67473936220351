












import { Component, Prop, Vue } from 'vue-property-decorator';
import { IImportHistory } from '@/store/types/IImportHistory';
import dashboardimportstabmobileitem from '@/pages/dashboard/components/DashboardImports/DashboardImportsTabMobileItem.vue';
@Component({
  components: {
    dashboardimportstabmobileitem,
  },
})
export default class DashboardImportsTabMobile extends Vue {
  @Prop() imports!: IImportHistory[];
}
