
















































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { IExportHistory } from '@/store/types/IExportHistory';
import exportstatusbadge from '@/pages/dashboard/components/ExportStatusBadge.vue';
import exportcontentbadge from '@/pages/dashboard/components/ExportContentBadge.vue';
import exportedrecordcount from '@/pages/dashboard/components/ExportedRecordCount.vue';
import exportformatbadge from '@/pages/dashboard/components/ExportFormatBadge.vue';
import exportinitiationbadge from '@/pages/dashboard/components/ExportInitiationBadge.vue';
import exportgranularitybadge from '@/pages/dashboard/components/ExportGranularityBadge.vue';
import { ICancelExport } from '@/store/types/ICancelExport';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';

@Component({
  components: {
    exportstatusbadge,
    exportcontentbadge,
    exportedrecordcount,
    exportformatbadge,
    exportinitiationbadge,
    exportgranularitybadge,
  },
})
export default class DashboardExportsTabDesktopItem extends Vue {
  @Prop() exp!: IExportHistory;
  cancelExportButtonDisabled = false;

  async cancelExport() {
    this.cancelExportButtonDisabled = true;
    await ExportUiStateModule.cancelCurentExportJob({
      exportProcessingHistoryId: this.exp.ExportHistoryId,
      processingUrl: '',
    } as ICancelExport);
    await ExplorerDataModule.loadExplorerDataAndRecords();
  }

  destinationTitle(exp: IExportHistory) {
    return exp.ExportDestinationId === 1
      ? exp.ExportDestination
      : exp.ExportIntegration;
  }
}
