






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICalendarValue } from '@/store/types/ICalendarValue';
import { IImportHistory } from '@/store/types/IImportHistory';
import dashboardimportstabdesktopitem from '@/pages/dashboard/components/DashboardImports/DashboardImportsTabDesktopItem.vue';

@Component({
  components: {
    dashboardimportstabdesktopitem,
  },
})
export default class DashboardImportsTabDesktop extends Vue {
  @Prop() imports!: IImportHistory[];

  calendarEndDate = new Date();

  groupBy(array: any, prop: string) {
    return array.reduce((groups: any, item: any) => {
      const val = item[prop];
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }

  get calendarValues() {
    // the map removes the time element for the date
    // the reduce then removes duplicate dates and generates a count for each date
    // see // https://dev.to/marinamosti/removing-duplicates-in-an-array-of-objects-in-js-with-sets-3fep
    return this.imports
      .map((i) => ({
        date: new Date(i.DateUploadedUTC).toDateString(),
        count: 1,
      }))
      .reduce((acc: ICalendarValue[], current) => {
        const x = acc.find(
          (item: ICalendarValue) => item.date === current.date,
        );
        if (!x) {
          return acc.concat(current);
        } else {
          x.count++;
          return acc;
        }
      }, []);
  }
}
