var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
    title: ((_vm.$t('TL_EXPORT_STATUS_BADGE_CANCELLED_BY')) + " " + (_vm.exporthistory.ExportCancelledBy)),
    placement: 'bottom',
    disabled: _vm.exporthistory.DashboardStatusId !== 5,
  }),expression:"{\n    title: `${$t('TL_EXPORT_STATUS_BADGE_CANCELLED_BY')} ${\n      exporthistory.ExportCancelledBy\n    }`,\n    placement: 'bottom',\n    disabled: exporthistory.DashboardStatusId !== 5,\n  }"}],staticClass:"p-2",class:{
    queued: _vm.exporthistory.DashboardStatusId === 1,
    processing: _vm.exporthistory.DashboardStatusId === 2,
    paused: _vm.exporthistory.DashboardStatusId === 3,
    failed: _vm.exporthistory.DashboardStatusId === 4,
    cancelled: _vm.exporthistory.DashboardStatusId === 5,
    complete: _vm.exporthistory.DashboardStatusId === 6,
  },attrs:{"size":"lg"}},[_vm._v("\n  "+_vm._s(_vm.exporthistory.DashboardStatus)+"\n  "),(_vm.exporthistory.DashboardStatusId === 5)?_c('font-awesome-icon',{attrs:{"icon":"info-circle","fixed-width":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }