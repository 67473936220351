
















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IBook } from '@/store/types/IBook';
import dashboardbookstabdesktopitem from '@/pages/dashboard/components/DashboardBooks/DashboardBooksTabDesktopItem.vue';

@Component({
  components: {
    dashboardbookstabdesktopitem,
  },
})
export default class DashboardBooksTabDesktop extends Vue {
  @Prop() books!: IBook[];
}
